import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _402ef896 = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _4cb5f834 = () => interopDefault(import('../pages/ar.vue' /* webpackChunkName: "pages/ar" */))
const _71a64cda = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _08b6ebca = () => interopDefault(import('../pages/distributions.vue' /* webpackChunkName: "pages/distributions" */))
const _cf7ec8a6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _78d050d6 = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _4607cbc8 = () => interopDefault(import('../pages/invoice.vue' /* webpackChunkName: "pages/invoice" */))
const _0ba6a76f = () => interopDefault(import('../pages/market-sum.vue' /* webpackChunkName: "pages/market-sum" */))
const _8a87c26a = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _43126330 = () => interopDefault(import('../pages/sectors.vue' /* webpackChunkName: "pages/sectors" */))
const _147498d1 = () => interopDefault(import('../pages/subscriptions.vue' /* webpackChunkName: "pages/subscriptions" */))
const _f9a85514 = () => interopDefault(import('../pages/terms-conditions/index.vue' /* webpackChunkName: "pages/terms-conditions/index" */))
const _db8afdee = () => interopDefault(import('../pages/auth/forget-password.vue' /* webpackChunkName: "pages/auth/forget-password" */))
const _77684e77 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _51942305 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _716e333b = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _99bacd06 = () => interopDefault(import('../pages/auth/verification.vue' /* webpackChunkName: "pages/auth/verification" */))
const _15b9140d = () => interopDefault(import('../pages/dashboard/account.vue' /* webpackChunkName: "pages/dashboard/account" */))
const _4df6e20e = () => interopDefault(import('../pages/dashboard/calendar.vue' /* webpackChunkName: "pages/dashboard/calendar" */))
const _76006bde = () => interopDefault(import('../pages/dashboard/checkout-error.vue' /* webpackChunkName: "pages/dashboard/checkout-error" */))
const _bd4df62c = () => interopDefault(import('../pages/dashboard/comparisons.vue' /* webpackChunkName: "pages/dashboard/comparisons" */))
const _06c72fb8 = () => interopDefault(import('../pages/dashboard/favorites-list.vue' /* webpackChunkName: "pages/dashboard/favorites-list" */))
const _1c2078d6 = () => interopDefault(import('../pages/dashboard/my-shares.vue' /* webpackChunkName: "pages/dashboard/my-shares" */))
const _4113953a = () => interopDefault(import('../pages/dashboard/processing.vue' /* webpackChunkName: "pages/dashboard/processing" */))
const _2bba0e19 = () => interopDefault(import('../pages/dashboard/thanks.vue' /* webpackChunkName: "pages/dashboard/thanks" */))
const _31470c20 = () => interopDefault(import('../pages/dashboard/upgrade-subscribtion.vue' /* webpackChunkName: "pages/dashboard/upgrade-subscribtion" */))
const _a21c49ae = () => interopDefault(import('../pages/dashboard/wallets-management/index.vue' /* webpackChunkName: "pages/dashboard/wallets-management/index" */))
const _3c40fa7c = () => interopDefault(import('../pages/dashboard/welcome.vue' /* webpackChunkName: "pages/dashboard/welcome" */))
const _1004585c = () => interopDefault(import('../pages/tools/discover.vue' /* webpackChunkName: "pages/tools/discover" */))
const _1c5e93d1 = () => interopDefault(import('../pages/dashboard/wallets-management/_id.vue' /* webpackChunkName: "pages/dashboard/wallets-management/_id" */))
const _7835bdc2 = () => interopDefault(import('../pages/blog/_id.vue' /* webpackChunkName: "pages/blog/_id" */))
const _e3d0c27a = () => interopDefault(import('../pages/company/_id.vue' /* webpackChunkName: "pages/company/_id" */))
const _891482a8 = () => interopDefault(import('../pages/marketing/_id.vue' /* webpackChunkName: "pages/marketing/_id" */))
const _57c957f3 = () => interopDefault(import('../pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _402ef896,
    name: "about-us___ar"
  }, {
    path: "/ar",
    component: _4cb5f834,
    name: "ar___ar"
  }, {
    path: "/blog",
    component: _71a64cda,
    name: "blog___ar"
  }, {
    path: "/distributions",
    component: _08b6ebca,
    name: "distributions___ar"
  }, {
    path: "/en",
    component: _cf7ec8a6,
    name: "index___en"
  }, {
    path: "/faqs",
    component: _78d050d6,
    name: "faqs___ar"
  }, {
    path: "/invoice",
    component: _4607cbc8,
    name: "invoice___ar"
  }, {
    path: "/market-sum",
    component: _0ba6a76f,
    name: "market-sum___ar"
  }, {
    path: "/news",
    component: _8a87c26a,
    name: "news___ar"
  }, {
    path: "/sectors",
    component: _43126330,
    name: "sectors___ar"
  }, {
    path: "/subscriptions",
    component: _147498d1,
    name: "subscriptions___ar"
  }, {
    path: "/terms-conditions",
    component: _f9a85514,
    name: "terms-conditions___ar"
  }, {
    path: "/auth/forget-password",
    component: _db8afdee,
    name: "auth-forget-password___ar"
  }, {
    path: "/auth/login",
    component: _77684e77,
    name: "auth-login___ar"
  }, {
    path: "/auth/register",
    component: _51942305,
    name: "auth-register___ar"
  }, {
    path: "/auth/reset-password",
    component: _716e333b,
    name: "auth-reset-password___ar"
  }, {
    path: "/auth/verification",
    component: _99bacd06,
    name: "auth-verification___ar"
  }, {
    path: "/dashboard/account",
    component: _15b9140d,
    name: "dashboard-account___ar"
  }, {
    path: "/dashboard/calendar",
    component: _4df6e20e,
    name: "dashboard-calendar___ar"
  }, {
    path: "/dashboard/checkout-error",
    component: _76006bde,
    name: "dashboard-checkout-error___ar"
  }, {
    path: "/dashboard/comparisons",
    component: _bd4df62c,
    name: "dashboard-comparisons___ar"
  }, {
    path: "/dashboard/favorites-list",
    component: _06c72fb8,
    name: "dashboard-favorites-list___ar"
  }, {
    path: "/dashboard/my-shares",
    component: _1c2078d6,
    name: "dashboard-my-shares___ar"
  }, {
    path: "/dashboard/processing",
    component: _4113953a,
    name: "dashboard-processing___ar"
  }, {
    path: "/dashboard/thanks",
    component: _2bba0e19,
    name: "dashboard-thanks___ar"
  }, {
    path: "/dashboard/upgrade-subscribtion",
    component: _31470c20,
    name: "dashboard-upgrade-subscribtion___ar"
  }, {
    path: "/dashboard/wallets-management",
    component: _a21c49ae,
    name: "dashboard-wallets-management___ar"
  }, {
    path: "/dashboard/welcome",
    component: _3c40fa7c,
    name: "dashboard-welcome___ar"
  }, {
    path: "/en/about-us",
    component: _402ef896,
    name: "about-us___en"
  }, {
    path: "/en/ar",
    component: _4cb5f834,
    name: "ar___en"
  }, {
    path: "/en/blog",
    component: _71a64cda,
    name: "blog___en"
  }, {
    path: "/en/distributions",
    component: _08b6ebca,
    name: "distributions___en"
  }, {
    path: "/en/faqs",
    component: _78d050d6,
    name: "faqs___en"
  }, {
    path: "/en/invoice",
    component: _4607cbc8,
    name: "invoice___en"
  }, {
    path: "/en/market-sum",
    component: _0ba6a76f,
    name: "market-sum___en"
  }, {
    path: "/en/news",
    component: _8a87c26a,
    name: "news___en"
  }, {
    path: "/en/sectors",
    component: _43126330,
    name: "sectors___en"
  }, {
    path: "/en/subscriptions",
    component: _147498d1,
    name: "subscriptions___en"
  }, {
    path: "/en/terms-conditions",
    component: _f9a85514,
    name: "terms-conditions___en"
  }, {
    path: "/tools/discover",
    component: _1004585c,
    name: "tools-discover___ar"
  }, {
    path: "/en/auth/forget-password",
    component: _db8afdee,
    name: "auth-forget-password___en"
  }, {
    path: "/en/auth/login",
    component: _77684e77,
    name: "auth-login___en"
  }, {
    path: "/en/auth/register",
    component: _51942305,
    name: "auth-register___en"
  }, {
    path: "/en/auth/reset-password",
    component: _716e333b,
    name: "auth-reset-password___en"
  }, {
    path: "/en/auth/verification",
    component: _99bacd06,
    name: "auth-verification___en"
  }, {
    path: "/en/dashboard/account",
    component: _15b9140d,
    name: "dashboard-account___en"
  }, {
    path: "/en/dashboard/calendar",
    component: _4df6e20e,
    name: "dashboard-calendar___en"
  }, {
    path: "/en/dashboard/checkout-error",
    component: _76006bde,
    name: "dashboard-checkout-error___en"
  }, {
    path: "/en/dashboard/comparisons",
    component: _bd4df62c,
    name: "dashboard-comparisons___en"
  }, {
    path: "/en/dashboard/favorites-list",
    component: _06c72fb8,
    name: "dashboard-favorites-list___en"
  }, {
    path: "/en/dashboard/my-shares",
    component: _1c2078d6,
    name: "dashboard-my-shares___en"
  }, {
    path: "/en/dashboard/processing",
    component: _4113953a,
    name: "dashboard-processing___en"
  }, {
    path: "/en/dashboard/thanks",
    component: _2bba0e19,
    name: "dashboard-thanks___en"
  }, {
    path: "/en/dashboard/upgrade-subscribtion",
    component: _31470c20,
    name: "dashboard-upgrade-subscribtion___en"
  }, {
    path: "/en/dashboard/wallets-management",
    component: _a21c49ae,
    name: "dashboard-wallets-management___en"
  }, {
    path: "/en/dashboard/welcome",
    component: _3c40fa7c,
    name: "dashboard-welcome___en"
  }, {
    path: "/en/tools/discover",
    component: _1004585c,
    name: "tools-discover___en"
  }, {
    path: "/",
    component: _cf7ec8a6,
    name: "index___ar"
  }, {
    path: "/en/dashboard/wallets-management/:id?",
    component: _1c5e93d1,
    name: "dashboard-wallets-management-id___en"
  }, {
    path: "/dashboard/wallets-management/:id?",
    component: _1c5e93d1,
    name: "dashboard-wallets-management-id___ar"
  }, {
    path: "/en/blog/:id",
    component: _7835bdc2,
    name: "blog-id___en"
  }, {
    path: "/en/company/:id?",
    component: _e3d0c27a,
    name: "company-id___en"
  }, {
    path: "/en/marketing/:id?",
    component: _891482a8,
    name: "marketing-id___en"
  }, {
    path: "/en/news/:id",
    component: _57c957f3,
    name: "news-id___en"
  }, {
    path: "/blog/:id",
    component: _7835bdc2,
    name: "blog-id___ar"
  }, {
    path: "/company/:id?",
    component: _e3d0c27a,
    name: "company-id___ar"
  }, {
    path: "/marketing/:id?",
    component: _891482a8,
    name: "marketing-id___ar"
  }, {
    path: "/news/:id",
    component: _57c957f3,
    name: "news-id___ar"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
